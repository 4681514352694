import { CustomCard } from "../Shapes/CustomCard/CustomCard";
import { PointAlignment } from "../../../enums/PointAlignment";
import "./costs.scss";

interface ICostsRefProp {
    costsPageRef: React.RefObject<HTMLDivElement>;
}
export const Costs = ({costsPageRef}:ICostsRefProp) => {
  return<div className="costs-container" ref={costsPageRef}>
            <CustomCard pointAlignment={PointAlignment.Right}>
                <div className="cost-content" dir="rtl">
                    <h1>עלויות</h1>
                    <div>שיעור פרטני מקוון (שעה עגולה) -</div>
                    <div>130 ש"ח</div>
                    <div>שיעור קבוצתי מקוון (שעה עגולה) -</div>
                    <div>65 ש"ח למשתתף</div>
                </div>
            </CustomCard>
        </div> ;
};
