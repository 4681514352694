import { ReactNode } from "react";
import "./customForm.scss";
import { CustomLoader } from "../CustomLoader"; 

interface ICustomFormProp {
    children?: ReactNode; // Accept children
    onSubmit?: (event: React.FormEvent) => void; // Optional onSubmit handler
    isLoading?: boolean; // Add isLoading prop
}

export const CustomForm: React.FC<ICustomFormProp> = ({ children, onSubmit, isLoading }) => {
    return (
        <form className="custom-form" onSubmit={onSubmit}>
            {isLoading ? (
                <CustomLoader color="White" size={50} /> // Display loader when loading
            ) : (
                children
            )}
        </form>
    );
};
