import "./topic.scss";
import { CustomModal } from "../../CustomModal/CustomModal";
import {checkIcon} from "../../../assets";

interface TopicProps {
    title: string; //topic title
    hintText:string; //the text that will be displayed incase the user presses the hint icon
  }  

export const Topic = ({title, hintText}:TopicProps) => {
  const modalHeader="נושאי הלימוד הנכללים תחת הכותרת '"+title+"' :";
  const handleOpen = () => console.log(true);
  const handleClose = () => console.log(false);
  return (
    <div className="topic" dir="rtl">
      <img width="30" height="30" src={checkIcon} alt="check icon"></img>
      <span className="topic-text" >{title} </span>
      <CustomModal infoText={hintText} infoHeader={modalHeader} infoIconColor='black' />
    </div>
  );
};


