import "./index.scss";
import { AppBar, Toolbar, Typography, IconButton, Box, Drawer, List, ListItem, ListItemText } from "@mui/material";
import { LocalOffer as PricingIcon, CalendarMonth as SchedulingIcon, Home, Phone, QuestionAnswer, Menu } from "@mui/icons-material";
import { useState } from "react";

interface INavIcons {
  icon: any;
  text: string;
}

interface INavBarProps {
  currentSection: string;
  backgroudColor: string;
  handleScrollToSection: (section: string) => void;
}

export const NavBar = ({ currentSection, backgroudColor, handleScrollToSection }: INavBarProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const nav: INavIcons[] = [
    {
      icon: <Home />,
      text: "דף הבית",
    },
    {
      icon: <PricingIcon />,
      text: "עלויות",
    },
    {
      icon: <Phone />,
      text: "יצירת קשר",
    },
    {
      icon: <SchedulingIcon />,
      text: "קביעת שיעור",
    },
    {
      icon: <QuestionAnswer />,
      text: "שאלות ותשובות",
    },
  ];

  //function that return the class name according to the current navbar selection
  const getPageStyleClass = () => {
    let currentPageStyling = ""; 
    switch(currentSection){
      case "דף הבית":
        currentPageStyling="main-page-styling";
        break;
      case "יצירת קשר":
        currentPageStyling="contact-page-styling";
        break;
      case "עלויות, יצירת קשר":
        currentPageStyling="contact-page-styling";
        break;
      case "עלויות":
        currentPageStyling="costs-page-styling";
        break;
      case "קביעת שיעור":
        currentPageStyling="schedule-page-styling";
        break;
    }
    return currentPageStyling;
  };
  const currentPageStyling = getPageStyleClass(); 

  return (
    <AppBar position="fixed" sx={{ backgroundColor: backgroudColor }}>
      <Toolbar dir="rtl">
        <Box className="desktop-menu" sx={{ flexGrow: 1 }} justifyContent="center">
            {nav.map((navItem: INavIcons, index: number) => {
              const isCurrent = currentSection.includes(navItem.text); 

              return (
                <IconButton 
                  key={index} 
                  sx={{ mr: 2 }} 
                  className={`nav-icon ${currentPageStyling}`} 
                  onClick={() => handleScrollToSection(navItem.text)}
                >
                  {navItem.icon}
                  <Typography 
                    variant="body1" 
                    style={{ textDecoration: isCurrent ? "underline" : undefined }} 
                    className={`nav-icon-text ${currentPageStyling}`}
                  >
                    {navItem.text}
                  </Typography>
                </IconButton>
              );
            })}
        </Box>
        <IconButton className={`burger-menu-icon ${currentPageStyling}`} onClick={toggleDrawer} >
          <Menu />
        </IconButton>
      </Toolbar>

      <Drawer className="burger-menu-drawer" sx={{ backgroundColor: 'transparent' }}
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer}
      >
        <List className="burger-menu-drawer-list" >
          {nav.map((navItem: INavIcons, index: number) => {
            const isCurrent = currentSection.includes(navItem.text); 

            return(
              <ListItem 
                key={index} 
                onClick={() => {
                  handleScrollToSection(navItem.text);
                  toggleDrawer();
                }}
                style={{ textDecoration: isCurrent ? "underline" : undefined }} 
              >
                <ListItemText primary={navItem.text} />
                </ListItem>
            )
          })}
        </List>
      </Drawer>
    </AppBar>
  );
};
