import React, { useState } from 'react';
import './custom-switch.scss';

interface SwitchProps {
  value1: string; // Label for the first option
  value2: string; // Label for the second option
  defaultSelected?: string; // Optional default selected value
  onChange: (value: string) => void; // Callback function to be invoked when the selection changes
}

const CustomSwitch: React.FC<SwitchProps> = ({ value1, value2, defaultSelected = value1, onChange }) => {
  // Initialize selected state with defaultSelected if provided
  const [selected, setSelected] = useState<string>(defaultSelected);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSelected(newValue);
    onChange(newValue); // Invoke the callback with the new value
  };

  // Generate unique IDs for the options
  const generateId = (value: string) => value.toLowerCase().replace(/\s+/g, '-');

  return (
    <div className="switch-container">
      <form className="tabber" dir="rtl">
        <label htmlFor={generateId(value1)}>{value1}</label>
        <input
          id={generateId(value1)}
          type="radio"
          value={value1}
          checked={selected === value1}
          onChange={handleChange}
        />

        <label htmlFor={generateId(value2)}>{value2}</label>
        <input
          id={generateId(value2)}
          type="radio"
          value={value2}
          checked={selected === value2}
          onChange={handleChange}
        />
        
        <div className={`blob ${selected === value1 ? 'right' : 'left'}`}></div>
      </form>
    </div>
  );
};

export default CustomSwitch;
