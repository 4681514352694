import React from 'react';
import "./scheduleHeaderRow.scss";
import { scheduleType } from "../types/scheduleType";
import { CustomModal } from "../../CustomModal/CustomModal";
import CustomSwitch from "./CustomSwitch/CustomSwitch";
import { SchedulePageInfo} from "../interfaces/interfaces";

interface IScheduleHeaderRowProp {
    handleScheduleToggle: (value: string) => void; 
    infoObject: SchedulePageInfo;
    currentScheduleType:scheduleType;
}

export const ScheduleHeaderRow = ({ handleScheduleToggle, infoObject,currentScheduleType }: IScheduleHeaderRowProp) => {
    const handleSwitchChange = (value: string) => {
        handleScheduleToggle(value);
    };

    return (
        <div className="schedule-header-row">
            <div className="header-first-row" dir="rtl">
                <h1> {infoObject.headerText? infoObject.headerText: 'זמינות שיעורים'} </h1>
                <CustomModal infoText={currentScheduleType==='private'?infoObject.hints.privateLessonHint:infoObject.hints.groupLessonHint} infoIconColor={'White'} />
            </div>
            <CustomSwitch
                value1="פרטיים"
                value2="קבוצתיים"
                defaultSelected="פרטיים"
                onChange={handleSwitchChange} // Pass the callback function
            />
        </div>
    );
};
