import "./index.scss";
import {ProfilePic} from "../../assets/";
import { Topic } from "./Topic/Topic";
import {mainPageBackgroundVideo } from "../../assets";
import {topics} from "../Constants/topics"
import { useEffect, useRef } from "react";

interface IMainPageRefProp {
  mainPageRef: React.RefObject<HTMLDivElement>;
}

const headerText="מורה פרטית - לידור מרקוביץ'";
const aboutMeTextLine1 = "בוגרת תואר במדעי המחשב מעבירה שיעורים פרטיים וקבוצתיים בנושאים הבאים:";


export const MainPage = ({ mainPageRef }: IMainPageRefProp) => {
  const videoRef = useRef<HTMLVideoElement>(null); //in safari the video auto play attribute wont apply due to privacy policies thats why it requires some user interaction in order to be played

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="main-page" ref={mainPageRef} >
          <div className="video-container">
            <video autoPlay loop muted playsInline className="video-bg" src={mainPageBackgroundVideo} ref={videoRef} />
            <div className="overlay">
              <div className="main-page-content">
                <div className="header-container" dir="rtl">
                {/* <CustomSwitch value1="aaaaaaa1" value2="bbbbbb2" defaultSelected="פרטיים"/> */}

                  <img src={ProfilePic} alt="Profile" />
                 
                  <h1>{headerText.split(' - ').map((line, index) => ( 
                    <div key={index}>{line}</div> 
                  ))}</h1>  {/*this will automatically wrap the text based on screen size. */}

                </div>
                <div className="about-me-text" dir="rtl"> 
                  <div>{aboutMeTextLine1}</div>
                </div>
                <div className="topic-list">
                  {topics.map((topic, index) => (
                    <Topic key={index} title={topic.title}  hintText={topic.hintText} />
                  ))}
                </div>
              </div>
            </div>

          </div>       
    </div>
  );
};


