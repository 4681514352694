import { Dot } from "../Dot/Dot";
import { Line } from "../Line/Line";
import "./dotsLine.scss";

interface IDotsLineProp {
    rowsNo: number;
}

export const DotsLine = ({ rowsNo }:IDotsLineProp) => {
  const rows = Array.from({ length: rowsNo }, (_, index: number) => index); // Create an array with rowCount elements to map over

  return <div className="rows-container"> 
            {rows.map((rowIndex) => (
                <div key={rowIndex} className="row">
                    <Dot color={'#1c1c1c'} />
                    <Line />
                    <Dot color={'#1c1c1c'} />
                </div>
            ))}
        </div>
};
