import { ReactNode, useState } from "react";
import "./customModal.scss";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close'; // Import the Close icon
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {customInfoIcon} from "../../assets";

interface ICustomModalProp {
    infoText?: string;
    infoHeader?: ReactNode;
    infoIconColor: string; 
    isModalOpen?: boolean; // The initial state of the modal
    children?: ReactNode; // Accept children
    closeModal?: () => void; // Function to close the modal
}

export const CustomModal: React.FC<ICustomModalProp> = ({ infoHeader, infoText, infoIconColor, isModalOpen = false, children, closeModal }) => {
    const [infoModalOpen, setInfoModalOpen] = useState(isModalOpen); // State for controlling modal visibility

    const handleInfoClick = () => {
        setInfoModalOpen(true); // Show the modal when the info icon is clicked
    };

    const handleModalClose = () => {
        setInfoModalOpen(false); // Close the modal
        closeModal?.(); // Call the closeModal function if provided
    };

    return (
        <div className="custom-modal">
            {!isModalOpen && 
                <IconButton aria-label="info" onClick={handleInfoClick} sx={{ color: infoIconColor }} className="custom-modal-info-icon-wrapper">
                     <img src={customInfoIcon} alt="Info" className="custom-modal-info-icon" />
                </IconButton>
            }

            <Modal dir="rtl"
                open={infoModalOpen}
                onClose={handleModalClose}
                aria-labelledby="info-modal-title"
                aria-describedby="info-modal-description"
            >
                <Box className="custom-modal-content">
                    {/* Close button */}
                    <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} 
                        aria-label="close"
                        onClick={handleModalClose}
                        className="custom-modal-close-button"
                    >
                        <CloseIcon />
                    </IconButton>

                    {/* Render modal header if it exists */}
                    {infoHeader && <Typography className="modal-header" variant="h5" sx={{ mt: 1 }}>{infoHeader}</Typography>}
                    {/* Render modal text if it exists */}
                    {infoText && <Typography sx={{ mt: 2 }} dangerouslySetInnerHTML={{ __html: infoText }} />}
                    {/* Render children if they exist */}
                    {children}
                </Box>
            </Modal>
        </div>
    );
};
