import { MouseEventHandler } from "react";
import { InactiveOverlay } from "./InactiveOverlay/InactiveOverlay";
import "./calenderDay.scss";

interface ICalenderDayProps {
  day: number;
  month: number; 
  isActive: boolean;
  handleClick:MouseEventHandler<HTMLDivElement>;
}

export const CalenderDay = ({ day, month, isActive, handleClick }: ICalenderDayProps) => {
 
  return (
    <div className={`calender-day ${isActive ? 'active' : 'inactive'}`} onClick={isActive ? handleClick : undefined} >
      {`${day}.${month}`} {/* Concatenate day and month */} 
        {!isActive && <InactiveOverlay /> }
    </div>
  );
};
