import "./calender.scss";
import { useContext, useState } from "react";
import { CalenderDay } from "./CalenderDay/CalenderDay";
import { CalenderHeader } from "./CalenderHeader/CalenderHeader";
import { CustomModal } from "../../CustomModal/CustomModal";
import { CustomForm } from "../../CustomForm/CustomForm";
import { topics } from "../../Constants/topics";
import { postRequest } from "../../../api/request/addRequest";
import {  ScheduleContext  } from "../context/ScheduleContext";
import { CustomLoader } from "../../CustomLoader";

export const Calender = () => {

  const [isDaySelected, setDaySelected] = useState(false);
  const [selectedDay, setSelectedDay] = useState<Date | null>(null); 
  const [formData, setFormData] = useState({
    fullName: "",
    phone: "",
    subject: "",
    hour: "",
    institute: ""
  });
  const { currentScheduleType, infoObject } = useContext(ScheduleContext)!; // Using context directly
  const [isThankYouModalOpen, setThankYouModalOpen] = useState(false); // State for Thank You modal after submission

  const handleDayClick = (date: Date) => {
    setSelectedDay(date); 
    setDaySelected(true);
    // Reset the hour in formData 
    setFormData(prevFormData => ({
    ...prevFormData,
    hour: "" 
  }));
  };

  const handleCloseModal = () => {
    setSelectedDay(null); // Reset the selected day when the modal is closed
    setDaySelected(false);
    setThankYouModalOpen(false); // Close Thank You modal when closing other modal
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault(); 

    const requestBody = {
      date: selectedDay?.toISOString() || "",
      lessons: [
        {
          hour:formData.hour , 
          students: [
            {
              phone: formData.phone,
              name: formData.fullName
            }
          ],
          isGroup: currentScheduleType === 'group',
          subject: formData.subject + (formData.institute ? ` במוסד לימוד=${formData.institute}` : ""),
          requestSubmissionTime: new Date().toISOString() // Add current date here
        }
      ],
    };
    console.log("formData.hour="+formData.hour);

    console.log("before post a request");
    console.log(requestBody);
    try {
      await postRequest(requestBody); 
      handleCloseModal();
      setThankYouModalOpen(true); // Open the Thank You modal
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { id, value } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [id]: value
    }));
  };

  // Get scheduled hours for the selected day
  const scheduledHours = selectedDay ? infoObject.scheduleData?.find(day => 
    new Date(day.date).toISOString().split('T')[0] === selectedDay.toISOString().split('T')[0]
  )?.lessons.map(lesson => lesson.hour) : [];

  // Get available hours for the selected day
  const availableHours = selectedDay ? infoObject.availableTimes?.find(day => 
    new Date(day.date).toISOString().split('T')[0] === selectedDay.toISOString().split('T')[0]
  )?.hours : [];

  // Set hoursOptions based on the schedule type
  const hoursOptions = currentScheduleType === 'group' 
    ? scheduledHours || []  // Use scheduled hours only for group lessons
    : availableHours || [];  // Use available hours for private lessons
    
  //Helper
  //TODO create utils
  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year
    return `${day}.${month}.${year}`;
  }; 

  //find the scheduled lesson by the selected hour
  const scheduledLesson = selectedDay && formData.hour ? infoObject.scheduleData?.find(day => 
    new Date(day.date).toISOString().split('T')[0] === selectedDay.toISOString().split('T')[0]
  )?.lessons.find(lesson => lesson.hour === formData.hour) : null; // Find the lesson by hour
  
  return (
    <div className="calender" dir="rtl">
      <CalenderHeader />
      {infoObject.scheduleData.length===0 ? <CustomLoader color="White" size={200} /> :
        <div className="days-container">
      {currentScheduleType === 'group' 
          ? infoObject.scheduleData.map((currentDay) => {
              const day = currentDay.date.getDate();
              const month = currentDay.date.getMonth() - 1;
              const dateStr = new Date(currentDay.date).toISOString();
              return (
                <CalenderDay
                  key={dateStr}
                  day={day}
                  month={month}
                  isActive={currentDay.lessons.length > 0 && currentDay.date>new Date()} 
                  handleClick={() => handleDayClick(currentDay.date)}
                />
              );
            }) 
          : infoObject.availableTimes?.map((currentDay) => {
              const day = currentDay.date.getDate();
              const month = currentDay.date.getMonth() + 1;
              const dateStr = new Date(currentDay.date).toISOString();
              return (
                <CalenderDay
                  key={dateStr}
                  day={day}
                  month={month}
                  isActive={currentDay.hours.length > 0 && currentDay.date>new Date()}
                  handleClick={() => handleDayClick(currentDay.date)}
                />
              );
            })
        }
      
        {selectedDay && 
          <CustomModal 
            infoHeader={
              <>
                <div>בקשה לקביעת שיעור</div>
                <div>בתאריך {selectedDay ? formatDate(selectedDay) : ''}</div>
              </>
            }            
            infoIconColor={"White"} 
            isModalOpen={true} 
            closeModal={handleCloseModal}
          >
            <CustomForm onSubmit={handleSubmit}>
              <input
                type="text"
                id="fullName"
                value={formData.fullName}
                onChange={handleChange}
                placeholder="שם מלא"
                required
              />
              <input
                type="tel"
                id="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="מספר נייד"
                required
              />

              {currentScheduleType === 'group' ? (
                <input
                  type="text"
                  id="subject"
                  value={scheduledLesson?.subject || ""}
                  readOnly 
                  placeholder="נושא השיעור"
                />
              ) : (
                <select
                  id="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled hidden>בחר נושא</option>
                  {topics.map((topic, index) => (
                    <option key={index} value={topic.title}>{topic.title}</option>
                  ))}
                </select>
              )}

              <select
                id="hour"
                value={formData.hour}
                onChange={handleChange}
                required
              >
                <option value="" disabled hidden>בחר שעה</option>
                {hoursOptions?.map((hour, index) => (
                  <option key={index} value={hour}>{hour}</option>
                ))}
              </select>
              <input
                type="text"
                id="institute"
                value={formData.institute}
                onChange={handleChange}
                placeholder="מוסד לימוד"
              />
              <button type="submit">שלח</button>
            </CustomForm>
          </CustomModal>
        }

        {isThankYouModalOpen && // Render Thank You modal conditionally
          <CustomModal
            infoHeader="הבקשה הוגשה בהצלחה!"
            infoIconColor={"Green"}
            isModalOpen={true}
            closeModal={() => setThankYouModalOpen(false)} // Close when user clicks
          >
            <div>אחרי אישור הבקשה תישלח אליך הודעת ווצאפ עם קישור הצטרפות לשיעור.</div>
          </CustomModal>
        }

        </div>
      }
    </div>
  );
};
