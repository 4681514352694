import { Dot } from "../Dot/Dot";
import { PointAlignment } from '../../../../enums/PointAlignment'; // Importing the enum
import { ReactNode } from "react";
import "./customCard.scss";

interface ICustomCardProps{
    pointAlignment:PointAlignment; 
    children?: ReactNode; // Accept children
}

export const CustomCard: React.FC<ICustomCardProps> = ({ pointAlignment, children }) => {
    return(
        <div className="custom-card" >                
                <div className={`custom-card-dot-container ${pointAlignment === PointAlignment.Left ? 'custom-card-left-dot-container' : 'custom-card-right-dot-container'}` }>
                    <Dot color="white"/>
                </div>
                <div className="glass-container">
                    <div className="glass glass-1" />
                    <div className="glass glass-2" />
                    <div className="glass glass-3" />
                    <div className="glass glass-4" />
                    <div className="glass glass-5" />
                    <div className="glass glass-6" />
                </div>
            {children} 
        </div>
    );
}