import { useContext, useEffect, useRef } from "react";
import { Calender } from "./Calender/Calender";
import { ScheduleHeaderRow } from "./ScheduleHeaderRow/ScheduleHeaderRow";
import "./index.scss";
import { schedulePageBackgroundVideo } from "../../assets";
import { ScheduleContext  } from "./context/ScheduleContext";

interface ISchedulePageRefProp {
  schedulePageRef: React.RefObject<HTMLDivElement>;
}

export const SchedulePage = ({ schedulePageRef  }: ISchedulePageRefProp) => {
  const { currentScheduleType, infoObject, toggleScheduleType } = useContext(ScheduleContext)!; // Using context directly
  const videoRef = useRef<HTMLVideoElement>(null); 
  // useEffect to start background video 
  //in safari the video auto play attribute wont apply due to privacy policies thats why it requires some user interaction in order to be played
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    }, 2000); 

    return () => clearTimeout(timeout);
  }, []);

  return (
      <div className="schedule-page" ref={schedulePageRef}> 
        <div className="video-container">
          <video autoPlay loop muted playsInline className="video-bg" src={schedulePageBackgroundVideo} ref={videoRef} />
          <div className="overlay">
            <ScheduleHeaderRow handleScheduleToggle={toggleScheduleType} infoObject={infoObject} currentScheduleType={currentScheduleType} /> 
            <Calender />
          </div>
        </div>
      </div>
  );
};
