import "./calenderHeader.scss";

export const CalenderHeader = () => {
  const daysOfTheWeek=['ראשון','שני','שלישי','רביעי','חמישי','שישי','שבת']

  return <div className="calender-header" dir="rtl">
    {daysOfTheWeek.map((day, index) => (
        <div key={index} className="calendar-header-day">
          {day}
        </div>
      ))}
  </div>;
};
