import { MainPage } from "./components/MainPage";
import { useEffect, useRef, useState } from "react";
import { ContactAndCoastsPage } from "./components/Contact_And_Coasts_Page";
import { NavBar } from "./components/NavBar";
import { SchedulePage } from "./components/schedule";
import { contactPageNavColor, costsPageNavColor, mainPageNavColor, schedulePageNavColor } from "./components/Constants/NavBarColors";
import { ScheduleProvider } from './components/schedule/context/ScheduleContext';

import './App.scss';

function App() {
  const [currentSection, setCurrentSection] = useState<string>("דף הבית");
  const [navBarBackground, setNavBarBackground] = useState('transparent'); // State for navbar transparency

  const mainPageRef = useRef<HTMLDivElement>(null);
  const contactAndCostsPageRef = useRef<HTMLDivElement>(null);
  const contactPageRef = useRef<HTMLDivElement>(null);
  const costsPageRef = useRef<HTMLDivElement>(null);
  const schedulePageRef = useRef<HTMLDivElement>(null);

  //checks whether the navbar is above the content of the current page, and if so - changes the nav background to the given one. otherwise - set the background to transparent 
  const handleNavBarBackground=(currentNavBarPos:number,currentDivSelector:string,currentPageSelector:string,backgroundColor:string) => {
    const currentDiv = document.querySelector(currentDivSelector) as HTMLElement | null;
    const currentDivHeight=currentDiv?.clientHeight || 0;
    const currentDivTop=currentDiv?.offsetTop || 0;
    const currentPageOffsetTop=document.querySelector(currentPageSelector)?.clientHeight || 0; //inorder to calculate the navbar position relative to the current page, substracting the offsetTop of the current page is necessary 
  // console.log("currentDivHeight="+currentDivHeight+" currentDivTop="+currentDivTop+" currentPageOffsetTop="+currentPageOffsetTop+" currentNavBarPos="+currentNavBarPos);
    if( currentNavBarPos-currentPageOffsetTop <=currentDivHeight && currentNavBarPos >=currentDivTop)
      setNavBarBackground(backgroundColor);
    else
      setNavBarBackground('transparent');  
  }

  useEffect(() => {
    const handleScroll = () => {
      const mainPageTop = mainPageRef.current?.offsetTop || 0;
      const contactAndCostsPageTop = contactAndCostsPageRef.current?.offsetTop || 0;
      const contactPageTop = contactPageRef.current?.offsetTop || 0;
      const costsPageTop = costsPageRef.current?.offsetTop || 0;
      const schedulePageTop = schedulePageRef.current?.offsetTop || 0;
      const scrollY = Math.ceil(window.scrollY);

      if (scrollY >= mainPageTop && scrollY < contactAndCostsPageTop) {
        setCurrentSection("דף הבית");
        handleNavBarBackground(scrollY,".main-page-content","main-page",mainPageNavColor)
      }else if (scrollY >= contactAndCostsPageTop && scrollY <schedulePageTop) {
        if(costsPageTop===contactPageTop) //in desktop view the both costs and contact components will be displayed
        {
          setCurrentSection("עלויות, יצירת קשר");
          handleNavBarBackground(scrollY,".cards-container",".contact-and-coasts-page",contactPageNavColor);
        }
        else //closest component will be set to the current
        {
          if(Math.abs(scrollY-costsPageTop)>Math.abs(scrollY-contactPageTop))
          {
            setCurrentSection("יצירת קשר");
            handleNavBarBackground(scrollY,".cards-container",".contact-container",contactPageNavColor);
          }
          else
          {
            setCurrentSection("עלויות");
            handleNavBarBackground(scrollY,".costs-container",".contact-and-coasts-page",costsPageNavColor);
          }
        }
      }else if (scrollY >= schedulePageTop) {
        setCurrentSection("קביעת שיעור");
        handleNavBarBackground(scrollY,".schedule-header-row",".schedule-page",schedulePageNavColor);       
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollTo = (element: HTMLElement, duration: number, position: "start" | "end" = "start") => {
    const easeInOutQuad = (t: number) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    const start = window.scrollY;
    const target = position === "start" ? element.offsetTop : element.offsetTop + element.offsetHeight - window.innerHeight;
    const distance = target - start;
    let currentTime = 0;
    const increment = 20;
  
    const animateScroll = () => {
      currentTime += increment;
      const val = easeInOutQuad(currentTime / duration);
      window.scrollTo(0, start + distance * val);
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
  
    animateScroll();
  };
  
  
  const handleScrollToSection = (section: string) => {
    switch (section) {
      case "דף הבית":
        if (mainPageRef.current)
          scrollTo(mainPageRef.current, 1000);
        break;
      case "יצירת קשר":
        if(contactAndCostsPageRef.current)
          scrollTo(contactAndCostsPageRef.current, 1000, "start");
        break;
      case "עלויות":
        if(contactAndCostsPageRef.current)
          scrollTo(contactAndCostsPageRef.current, 1000, "end");
        break;
      case "קביעת שיעור":
        if (schedulePageRef.current)
          scrollTo(schedulePageRef.current, 1000);
        break;
      default:
        break;
    }
  };

  return (
    <div className="app">
        <NavBar currentSection={currentSection} backgroudColor={navBarBackground} handleScrollToSection={handleScrollToSection} />
        <MainPage mainPageRef={mainPageRef} />
        <ContactAndCoastsPage contactAndCoastsPageRef={contactAndCostsPageRef} contactPageRef={contactPageRef} costsPageRef={costsPageRef} />
        <ScheduleProvider>
          <SchedulePage schedulePageRef={schedulePageRef} />
        </ScheduleProvider>
    </div>
  );
}

export default App;
