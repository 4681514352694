import { ScheduleRequestBody } from "../../components/schedule/interfaces/interfaces";

const server=process.env.REACT_APP_SERVER_URL;

// Function to post lesson request data
export const postRequest = async (requestBody: ScheduleRequestBody) => {
  console.log("post request for lesson request");
  console.log(requestBody);
    try {
      const response = await fetch(server+'/request', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const responseData = await response.json();
      console.log('Success:', responseData);
  
      return responseData;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  