import React, { useState } from 'react';
import { facebookIcon, whatsappIcon } from "../../../assets";
import { CustomCard } from "../Shapes/CustomCard/CustomCard";
import { PointAlignment } from "../../../enums/PointAlignment";
import { topics } from "../../Constants/topics";
import "./contact.scss";
import { CustomForm } from "../../CustomForm/CustomForm";
import { postRequest } from '../../../api/contact/contactUs';
import { contactUsRequestBody } from './interfaces/postRequestBody';
import { CustomModal } from '../../CustomModal/CustomModal';
import { CustomLoader } from "../../CustomLoader"; // Import your CustomLoader

interface IContactRefProp {
    contactPageRef: React.RefObject<HTMLDivElement>;
}

export const Contact = ({ contactPageRef }: IContactRefProp) => {
    const [fullName, setFullName] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [note, setNote] = useState('');
    const [isThankYouModalOpen, setThankYouModalOpen] = useState(false);
    const [isLoading, setLoading] = useState(false); // State for loading

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        
        const requestBody: contactUsRequestBody = {
            fullName,
            phone,
            subject,
            note,
        };

        setLoading(true); // Start loading

        try {
            const response = await postRequest(requestBody);
            console.log('Response:', response);
            setThankYouModalOpen(true);
            setFullName('');
            setPhone('');
            setSubject('');
            setNote('');
        } catch (error) {
            console.error('Error while submitting form:', error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (  
        <div className="contact-container" ref={contactPageRef}> 
            <CustomCard pointAlignment={PointAlignment.Left}>
                <div className="contact-content">       
                    <h1>יצירת קשר</h1>
                    <div className="contact-icons">
                        <a href="https://www.facebook.com/LidorMarko315" target="_blank" rel="noopener noreferrer">
                            <img alt='facebook icon' src={facebookIcon} />
                        </a>
                        <a href="https://wa.me/+972549276858" target="_blank" rel="noopener noreferrer">
                            <img alt='whatsapp icon' src={whatsappIcon} className="moving-icon" />
                        </a>
                    </div>
                    <h2>השארת פרטים לחזרה</h2>
                    <CustomForm onSubmit={handleSubmit} isLoading={isLoading}> 
                        <input
                            type="text"
                            id="fullName"
                            placeholder="שם מלא"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            required
                        />
                        <input
                            type="tel"
                            id="phone"
                            placeholder="מספר נייד"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                        />
                        <select
                            id="subject"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            required
                        >
                            <option value="" disabled hidden>בחר נושא</option>
                            {topics.map((topic, index) => (
                                <option key={index} value={topic.title}>{topic.title}</option>
                            ))}
                        </select>
                        <input
                            type="text"
                            id="note"
                            placeholder="הערה נוספת"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            maxLength={50}
                        />
                        <button type="submit">שלח</button>
                    </CustomForm>
                </div> 
            </CustomCard>

            {isThankYouModalOpen && 
                <CustomModal
                    infoHeader="פרטי התקשרות נשלחו בהצלחה"
                    infoIconColor={"Green"}
                    isModalOpen={true}
                    closeModal={() => setThankYouModalOpen(false)} 
                >
                    <div>נהיה בקשר בקרוב!</div>
                </CustomModal>
            }
        </div>               
    );
};
