import "./inactiveOverlay.scss";

export const InactiveOverlay = () => {
  return (
    <div className='inactive-overlay'>
        <span className="line line1"></span>
        <span className="line line2"></span>
        <span className="line line3"></span>
    </div>
  );
};
