const server=process.env.REACT_APP_SERVER_URL;

/**
 * Fetches schedule data from the server and returns an array representing each day for the next 5 weeks.
 * If a day is not included in the fetched schedule, the day will be represented with an empty lessons array.
 *
 * @async
 * @function
 * @returns {Promise<Object[]>} A promise that resolves to an array of objects, each representing a day within the next 5 weeks.
 */
async function getLessonsSchedule() {
    const res = await fetch(`${server}/schedule`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    });
    const data = await res.json();
    if (data.Message) return data.Message;

    // Create an array of dates for the next 5 weeks (including current week's days)
    const now = new Date();
    const startDate = new Date();
    const daysToDiminish = now.getDay(); // Days that have passed this week need to be handled
    const dates = [];
    if (daysToDiminish > 0) 
        startDate.setDate(now.getDate() - daysToDiminish); // Add previous days of the current week

    for (let i = 0; i < 35; i++) {
        const date = new Date(startDate);
        date.setDate((startDate.getDate() + i));
        dates.push(date.toISOString().split('T')[0]); // Keep only the date part
    }
    // Create a map of the fetched schedule data with date as the key
    const scheduleMap = data.reduce((acc, schedule) => {
        const date = schedule.date.split('T')[0];
        acc[date] = schedule;
        return acc;
    }, {});

    // Create the result array, ensuring each date is represented
    const result = dates.map(date => {
        const schedule = scheduleMap[date] || { date, lessons: [] };
        return {
            date: schedule.date,
            lessons: schedule.lessons || [],
        };
    });
    
    return result;
}

async function getDownTimes() {
    const res = await fetch(`${server}/operational_downtimes`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    });
    const data = await res.json();
    if (data.Message) return data.Message;
    return data;
}

/**
 * Fetches and processes schedule data for a schedule page.
 *
 * This function performs the following steps:
 * 1. Defines static header text and informational texts for both private and group lessons.
 * 2. Fetches raw schedule data from the server using the `getLessonsSchedule` function.
 * 3. Maps the raw data to a structured format suitable for the schedule page.
 * 4. Returns an object containing:
 *    - `headerText`: The header text to be displayed on the schedule page.
 *    - `infoText`: An object containing HTML strings with informational texts for private and group lessons.
 *    - `scheduleData`: An array of schedule items.
 *
 * @async
 * @function
 *   A promise that resolves to an object containing:
 *   - `headerText`: A string representing the header text for the schedule page.
 *   - `hints`: An object with HTML strings providing information about private and group lessons.
 *   - `scheduleData`: An array of schedule items.
 *   - `downTimesData`: An array of downtimes items.
 */
export async function getSchedulePageData() {
    const headerText = 'זמינות שיעורים';

    // TODO add this info to server instead of hardcoded
    const privateLessonHint = "<div>בטבלה מופיע פירוט השעות הפנויות לקביעת שיעור פרטי </div>" +
                              "<div>משבצת לבנה מציינת יום בו קיימות שעות פנויות לקביעת שיעור פרטי</div>" +
                              "<div>משבצת אפורה מייצגת יום בעל תפיסות מלאה</div>" +
                              "<div>ניתן ללחוץ על משבצת לבנה, לבחור שעה מתאימה ולמלא פרטים</div>" +
                              "<div>לאחר אישור של הבקשה תישלח הודעת ווצאפ עם קישור להצטרפות לשיעור </div>";
    const groupLessonHint = "<div>בטבלה מופיע פירוט השעות הפנויות להצטרפות לשיעור קבוצתי</div>" +
                            "<div>משבצת לבנה מציינת יום בו קיימות שעות פנויות להצטרפות לשיעור קבוצתי</div>" +
                            "<div>משבצת אפורה מייצגת יום בעל תפיסות מלאה</div>" +
                            "<div>ניתן ללחוץ על משבצת לבנה, לצפות בנושא השיעור הקבוצתי המתקיים, ולמלא פרטי בקשה להצטרפות</div>" +
                            "<div>לאחר אישור של הבקשה תישלח הודעת ווצאפ עם קישור להצטרפות לשיעור </div>";
    const hints = {
        privateLessonHint,
        groupLessonHint
        };

    const fetchedLessonSchedule = await getLessonsSchedule();
    const scheduleData = fetchedLessonSchedule.map(item => ({ // Map raw data to ScheduleItem[]
        _id: item._id,
        date: new Date(item.date),
        lessons: item.lessons.map(lesson => ({
            hour: lesson.hour,
            students: lesson.students,
            isGroup: lesson.isGroup,
            subject: lesson.subject,
            _id: lesson._id,
        }))
    }));

    const fetchedDownTimes = await getDownTimes();
    const downTimes= fetchedDownTimes.map(item => ({ // Map raw data to downTimeItem[] ??????????????????????????
        _id: item._id,
        date: new Date(item.day),
        hours: item.hours 
    }));

    return { headerText, hints, scheduleData, downTimes };
}
