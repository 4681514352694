import { Costs } from "./CostsPage/Costs";
import { Contact } from "./ContactPage/Contact";
import { DotsLine } from "./Shapes/DotsLine/DotsLine";
import "./index.scss";

interface IContactAndCoastsPageRefProp {
  contactAndCoastsPageRef: React.RefObject<HTMLDivElement>;
  contactPageRef: React.RefObject<HTMLDivElement>;
  costsPageRef: React.RefObject<HTMLDivElement>;
}

export const ContactAndCoastsPage = ({ contactAndCoastsPageRef,contactPageRef,costsPageRef }: IContactAndCoastsPageRefProp) => {
  return <div className="contact-and-coasts-page" ref={contactAndCoastsPageRef}>
    <div className="cards-container">
      <Contact contactPageRef={contactPageRef}/>
      <DotsLine rowsNo={3} />
      <Costs costsPageRef={costsPageRef}/>
    </div>     
  </div>;
};
