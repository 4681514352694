import { contactUsRequestBody } from '../../components/Contact_And_Coasts_Page/ContactPage/interfaces/postRequestBody';

const server=process.env.REACT_APP_SERVER_URL;

// Function to post contact us form details
export const postRequest = async (requestBody:contactUsRequestBody) => {
    console.log("post request for contact us form");
    console.log(requestBody);
      try {
        const response = await fetch(server+'/contact_us', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const responseData = await response.json();
        console.log('Success:', responseData);
    
        return responseData;
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    };
  